<template lang="pug">
  .company-list
    AppOverlayLoader(:state="loading")
    .save-action
      AppSaveButton(
        title="actions.save_changes"
        @save="saveChanges"
      )
    .title {{ $t('ota_management.company_list.title') }}
    .container-fluid
      .row.label
        FormFieldLabel(:title="$t('ota_management.company_list.exchange_rate_calculation')")
        AppTooltip(
          useAppIcon
          :title="$t('ota_management.company_list.tooltips.exchange_rate')"
          icon="exclamationCircleAlt"
        )
      .row.exchange-rate
        .value
          span
            | 100 円（JPY）
        AppIconButton.exchange-button(
          useAppIcon
          icon="arrowRightArrowLeft"
        )
        .exchange-rate-inputs
          AppInput(
            :disabled="isExchangeRateDisabled"
            :max="9999999.0"
            :class="{ invalid: $v.markupSetting.exchange_rate.$error }"
            v-model="markupSetting.exchange_rate"
          )
          AppDropdown(
            searchable
            allow-empty
            close-on-select
            title-key="code"
            :value="selectedCurrency"
            :items="currencies"
            @select="selectCurrency"
          )
      .row.table-row
        .title {{ $t('ota_management.company_list.table.title') }}
        BAlert.auto-publish-warning(
          show
          variant="warning"
        ) {{ $t("ota_management.company_list.auto_publish_warning") }}
        .table
          VueGoodTable(
            :rows="companyMarkups"
            :columns="columns"
            :sort-options="{ initialSortBy: {field: 'updated_at', type: 'desc'} }"
          )
            template(v-slot:table-column="props")
              .tooltip-header(v-if="isHeaderTooltipShown(props.column.field)")
                span(:title="props.column.label") {{ $t(props.column.label) }}
                AppTooltip(
                  useAppIcon
                  :title="$t(`ota_management.company_list.tooltips.${props.column.field}`)"
                  icon="exclamationCircle"
                )
              span(
                v-else
                :title="props.column.label"
              ) {{ $t(props.column.label) }}
            template(v-slot:table-row="{ column, row }")
              template(v-if="column.field === 'name'")
                span.name(
                  @click="openPlanList(row)"
                )
                  | {{ row.name }}
              .auto-publish(v-else-if="column.field === 'auto_publish'")
                AppToggle(
                  :value="row.released"
                  @input="switchReleased(row.company_id, row.released)"
                )
                .label
                  span {{ autoPublishLabel(row.released) }}
              .fee-data(v-else-if="column.field === 'fee'")
                MarkUpFee(
                  :types-disabled="row.fee.max_value !== null"
                  :invalid="companyMarkupValueErrors[row.company_id]"
                  :value="row.fee.value"
                  :markup-type="row.fee.type"
                  @change-markup-fee="changeCompanyMarkupFee(row, $event)"
                )
                .upper-limit(v-if="row.fee.max_value !== null")
                  span {{ upperLimitLabel(row.fee) }}
              template(v-else-if="column.field === 'updated_at'")
                span {{ row.updated_at.label }}
</template>

<script>
  // components
  import { VueGoodTable } from "vue-good-table"

  // misc
  import { findIndex, find, get, reduce } from "lodash-es"
  import { ON, OFF, JPY } from "@/config/constants"
  import { PERCENT_MARKUP, FIXED_MARKUP } from "@/config/constants"
  import { columns } from "./companyTableConfig"
  import "vue-good-table/dist/vue-good-table.css"

  // mixins
  import withValidations from "@/mixins/withValidations"
  import withStoreModule from "@/mixins/withStoreModule"

  // store modules
  import currenciesModule from "@/config/store/currencies"
  import companyMarkupsModule from "@/config/store/ota_management/markup_settings"

  const companyMarkupsMixin = withStoreModule(companyMarkupsModule, {
    name: "companyMarkupsMixin",
    readers: {
      markupSetting: "item",
      loading: "loading",
      sorting: "sorting"
    },
    actions: {
      fetchMarkupSetting: "FETCH_ITEM",
      updateMarkupSetting: "UPDATE_ITEM"
    }
  })

  const currenciesMixin = withStoreModule(currenciesModule, {
    name: "currencies",
    readers: { currencies: "items", currenciesLoading: "loading" },
    actions: { fetchCurrencies: "FETCH_ITEMS" }
  })

  const validationsMixin = withValidations(({ required, decimal, minValue }) => ({
    markupSetting: {
      exchange_rate: {
        required,
        decimal,
        minValue: value => value >= 0
      },
      currency_id: { required },
      company_markups: {
        $each: {
          company_id: { required },
          name: { required },
          released: { required },
          fee: {
            value: {
              required,
              minValue: minValue(0),
              lessThanMaxValue: (value, self) => !self.max_value || value <= self.max_value
            },
            max_value: { required: false },
            type: { required }
          },
          updated_at: { required: false }
        }
      }
    }
  }))

  export default {
    components: {
      VueGoodTable,
      MarkUpFee: () => import("./MarkUpFee"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppIcon: () => import("@/components/elements/AppIcon"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppInput: () => import("@/components/elements/AppInput"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppTooltip: () => import("@/components/elements/AppTooltip"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      AppToggle: () => import("@/components/elements/AppToggle")
    },

    mixins: [companyMarkupsMixin, currenciesMixin, validationsMixin],

    data() {
      return {
        autoPublishOn: ON.toUpperCase(),
        autoPublishOff: OFF.toUpperCase(),
        markupTypes: {
          fixed: FIXED_MARKUP.name,
          percent: PERCENT_MARKUP.name
        },
        columns
      }
    },

    mounted() {
      this.fetchCurrencies()
      this.fetchMarkupSetting()
    },

    computed: {
      isExchangeRateDisabled() {
        return this.markupSetting.currency_id === this.defaultCurrency?.id
      },

      defaultCurrency() {
        return find(this.currencies, ({ code }) => code === JPY.code)
      },

      selectedCurrency() {
        return find(this.currencies, ({ id }) => id === this.markupSetting.currency_id)
      },

      companyMarkups() {
        return get(this.markupSetting, "company_markups", [])
      },

      companyMarkupValueErrors() {
        return reduce(
          this.$v.markupSetting.company_markups.$each.$iter,
          (obj, markup) => {
            obj[markup.company_id.$model] = markup.fee.$error
            return obj
          },
          {}
        )
      }
    },

    methods: {
      upperLimitLabel({ max_value, type }) {
        return this.$t("ota_management.company_list.upper_limit", { value: max_value, type: this.markupTypes[type] })
      },

      openPlanList({ company_id }) {
        this.$router.push({ name: "OtaManagement/PlanList", params: { company_id } })
      },

      saveChanges() {
        this.validateAttributes()
        if (this.isValid) {
          this.updateMarkupSetting(this.markupSetting)
        }
      },

      selectCurrency({ id }) {
        this.markupSetting.currency_id = id
      },

      changeCompanyMarkupFee({ company_id }, fee) {
        const index = findIndex(this.companyMarkups, markup => markup.company_id === company_id)
        const markup = this.markupSetting.company_markups[index]
        this.markupSetting.company_markups.splice(index, 1, {
          ...markup,
          fee: { ...markup.fee, ...fee },
          _updated: true
        })
      },

      autoPublishLabel(autoPublish) {
        return autoPublish ? this.autoPublishOn : this.autoPublishOff
      },

      switchReleased(companyId, released) {
        const index = findIndex(this.companyMarkups, ({ company_id }) => company_id === companyId)
        this.markupSetting.company_markups[index].released = !released
        this.markupSetting.company_markups[index]._updated = true
      },

      isHeaderTooltipShown(field) {
        return field === "fee" || field === "auto_publish"
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .company-list
    position: relative
    ::v-deep
      .arrow
        &:before
          border-top-color: $default-white

      .tooltip-inner
        color: $default-gray
        background-color: $default-white
        box-shadow: 2px 2px 4px -1px $default-gray-medium

      .table-row
        margin-top: 30px

        .auto-publish-warning
          padding: 0.25rem 0.5rem
          margin-bottom: 0.5rem
          width: 100%

        .title
          margin-bottom: 10px

        .fee-data
          display: flex
          flex-direction: row
          align-items: center

          .upper-limit
            color: $default-gray
            font-size: 10px
            font-weight: 600
            margin-left: 7px

      .table
        &-td
          padding: .4em .75em .4em .75em
          vertical-align: middle

          .name
            cursor: pointer
            text-decoration: underline

            &:hover
              color: $default-purple

          .auto-publish
            display: flex
            justify-content: center

            .label
              margin-left: 8px

        &-th
          background: $default-gray-light !important

          button
            &:before,
            &:after
              border-left-width: 4px
              border-right-width: 4px

          &.updated-at
            width: 160px !important
            min-width: 160px !important

          &.name,
          &.markup-fee
            width: 450px !important
            min-width: 405px !important

          &.auto-publish
            width: 140px !important

          .tooltip-header
            display: flex

            .app-tooltip
              z-index: 1
              vertical-align: bottom
              height: 16px
              width: 16px

              svg
                fill: $default-purple !important
                vertical-align: bottom

    .title
      font-weight: 700
      font-size: 16px
      line-height: 24px
      color: $default-purple

    .save-action
      width: 100%
      display: flex
      justify-content: right
      margin-bottom: 16px

    .container-fluid
      .row
        font-size: 12.8px

        .exchange-rate-inputs
          display: flex
          justify-content: left
          padding-top: 8px

          &.invalid
            input
              +default-invalid-input

          input
            margin-top: 1px
            text-align: right
            height: 34px
            width: 178px

          ::v-deep
            .app-select
              padding: 0
              margin-left: 10px
              width: 92px
              height: 18px

              .dropdown-toggle
                height: 33px
                border: 1px solid #acb2f3

        ::v-deep
          .action-button
            +icon-button($default-purple)

          .app-icon-wrapper
            svg
              height: 20px
              width: 20px

        &.toggle
          margin-top: 10px

        &.label
          margin-top: 20px

          .required
            margin-left: 10px
            font-size: 12px
            color: $default-red

        &.exchange-rate
          .value
            padding: 17px 0 17px 12px
            font-weight: 600

          .exchange-button
            pointer-events: none
            border: none
            padding: 0
            margin: 10px

            &:hover
              box-shadow: none

            ::v-deep
              svg
                width: 24px
                fill: $default-purple
                transform: scale(1.3)

        .inputs
          display: flex
          justify-content: left

          .app-number-input
            margin-top: 10px
            ::v-deep
              input
                height: 33px
                width: 178px

          ::v-deep
            .app-select
              padding-top: 8px
              margin-left: 10px
              width: 82px
              height: 18px

              .dropdown-toggle
                border: 1px solid #acb2f3
</style>
